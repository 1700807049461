import React from 'react'
import { useStore } from "@nanostores/react"
import { useDevCode } from "../../hooks/useSecretCode"
import { $userFormExpanded, formSubmissionStore, storedClearbit } from "./store"

const CodeBlock = ({ label, children }) => {
  return (
    <>
      <h5 style={{ marginBottom: '0.5rem', padding: '0' }}>{label}</h5>
      <pre style={{ display: 'block', maxHeight: '200px', marginTop: '0', overflow: 'scroll', backgroundColor: '#111', color: '#fff' }}>
        {children}
      </pre>
    </>
  )
}

export const FormDebugger = ({ data }) => {
  const [show, reset] = useDevCode()
  const formExpanded = useStore($userFormExpanded)
  const clearbitData = useStore(storedClearbit)
  const submission = useStore(formSubmissionStore)

  if (!show) return null
  return (
    <div style={{ display: "block", position: 'fixed', top: 100, right: 0, color: '#000', backgroundColor: '#FC0', width: '90vw', height: 'auto', padding: '1rem 2rem 2rem', zIndex: 9999 }}>
      <button style={{ position: 'absolute', top: 0, right: 0, color: '#FC0', backgroundColor: '#000', borderColor: '#db0', padding: '1rem 2rem' }} onClick={reset}>ø</button>
      <p>Form Expanded: {formExpanded ? "yep" : "nope"}</p>
      <p>isLoading: {data.inputProps.isLoading ? "yep" : "nope"}</p>
      <CodeBlock label="formfields">{JSON.stringify(data.inputProps.form.values, undefined, 2)}</CodeBlock>
      <CodeBlock label="Clearbit Data">{JSON.stringify(clearbitData, undefined, 2)}</CodeBlock>
      <CodeBlock label="Submission Data">{JSON.stringify(submission, undefined, 2)}</CodeBlock>
    </div >
  )
}