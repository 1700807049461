import React from 'react'
import Typography from '@material-ui/core/Typography'

type SubmittedFormProps = {
  message: string
  downloadUrl: string
  downloadText: string
  filename: string
}

export const SubmittedForm = ({ message, downloadUrl, downloadText, filename }: SubmittedFormProps) => (
  <>
    <Typography
      className="formSubhead"
      component="p"
      style={{ maxWidth: 'unset' }}
      variant="h3"
    >
      {message}
    </Typography>
    {downloadUrl ? (
      <Typography
        className="formSubhead"
        component="p"
        style={{ maxWidth: 'unset' }}
        variant="body1"
      >
        <a
          download={filename}
          href={downloadUrl}
          rel="noopener noreferrer"
          style={{ color: '#fff' }}
          target="_blank"
        >
          {downloadText}
        </a>
      </Typography>
    ) : null}
  </>

)

export const DownloadNotFound = () => (
  <Typography className="formSubhead" component="p" variant="h3">
    Download file not found.
  </Typography>
)
