import React, { FC, useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'

import { useClearbitUserData } from '../../hooks/useClearbitUserData'
import { useDebouncedValue } from '../../hooks/useDebouncedValue'
import {
  useAskGDPRPermission,
  useForm,
} from '../../utils/form/useForm'
import validationSchemaEmailOnlyForm from '../../utils/form/validateEmailOnlyForm'
import { useHandleSubmitWithRecaptcha } from '../../utils/recaptcha'
import GoogleAnalyticsHiddenFields from '../GoogleAnalyticsHiddenFields'
import Button from '../UI/Button/Button'
import { FormWrapper } from './styles'
import { DownloadResourceFormProps, InitialFormValueTypes } from './types'
import { DownloadNotFound, SubmittedForm } from './submitted'
import { FormState } from './logic'
import { processClearbit, storedUser } from './store'
import { FormDebugger } from './debugger'


const DownloadResourceForm: FC<DownloadResourceFormProps> = ({
  resourceUrl,
  resourceDownloadUrl,
  formContent,
  manualDownloadPromptText,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [manualDownloadUrl, setManualDownloadUrl] = useState('')
  const [didShowGDPRConsent, setDidShowGDPRConsent] = useState(false)

  const businessEmailAddressRef = useRef<HTMLInputElement>(null)

  const downloadText = manualDownloadPromptText || ''
  const downloadFromURL =
    typeof window !== 'undefined' ? window?.location?.href : ''

  const nameArray = resourceUrl?.split('/') || []
  const filename = nameArray?.length
    ? nameArray[nameArray.length - 1]
    : 'download.pdf'

  const initialFormValues: InitialFormValueTypes = {
    firstName: '',
    lastName: '',
    businessEmail: '',
    country: '',
    GDPRConsent: false,
    jobTitle: '',
  }

  const formId = 'downloadForm'

  const isLinkedIn = () => {
    if (typeof window !== 'undefined') {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      if (/LinkedIn/i.test(String(userAgent))) {
        return true
      }

      return false
    }

    return false
  }

  /*  THERE ARE 3 FORM STATES
      1. No initial email saved, shows email only field, checks Clearbit when email is valid
      2. User previously filled the form out, email is displayed for easy re-submittal
      3. Full form is shown, either after X-ing out state #2, or after Clearbit returns incomplete data
      
      The GDPR checkbox is visible when:
      1. Clearbit data is valid, and user's region is Canada or EMEA
      2. User selects Region as Canada or EMEA as part of state #3
  */

  // const fullFormNeeded = 
  // const validationSchema = 

  const form = useForm({
    initialValues: initialFormValues,
    formId,
    endpoint: 'gated-content',
    errors: formContent,
    validationSchema: validationSchemaEmailOnlyForm(
      initialFormValues,
      formContent,
    ),
    onSuccess: (values) => {
      storedUser.set({ email: values.businessEmail, name: values.firstName })
      if (manualDownloadUrl) {
        if (isLinkedIn()) {
          document.location = resourceDownloadUrl || resourceUrl
        } else if (shouldOpenInNewTab(resourceDownloadUrl)) {
          window.open(resourceDownloadUrl, '_blank', 'noopener noreferrer')
        } else {
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = manualDownloadUrl
          a.target = '_blank'
          a.rel = 'noopener noreferrer'
          // specify document filename
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
        setIsSubmitted(true)
      }
    },
    emailField: 'businessEmail',
  })

  const handleSubmit = useHandleSubmitWithRecaptcha(
    form.handleSubmit,
    form.isValid,
  )

  const shouldAskGDPRPermission = useAskGDPRPermission(
    formContent,
    form?.values?.country || '',
    form?.values?.GDPRConsent || false,
  )

  const [
    debouncedEmail,
    forceSetDebouncedEmail,
    { isPending: hasPendingChanges },
  ] = useDebouncedValue(form?.values?.businessEmail, 500)

  const { rawData: clearbitFormData, isLoading, isError: cbBlocked } = useClearbitUserData(
    debouncedEmail || '',
  )
  useEffect(() => {
    const [allGood, requiredValues] = clearbitFormData ? processClearbit(clearbitFormData) : [false, {}]
    allGood && form.setValues({ ...form.values, ...requiredValues })
  }, [clearbitFormData, cbBlocked])

  const resetForm = () => {
    form.setValues(initialFormValues)
    forceSetDebouncedEmail('')
    setTimeout(() => businessEmailAddressRef?.current?.focus(), 100)
  }

  const shouldOpenInNewTab = (url: string | null | undefined): boolean => {
    const videoDomains = ['youtube.com', 'youtu.be', 'vimeo.com']
    const videoExtensions = [
      '.mp4',
      '.mov',
      '.avi',
      '.mkv',
      '.wmv',
      '.flv',
      '.webm',
    ]

    if (!url) {
      return false
    }

    const isVideoDomain = videoDomains.some((domain) => url?.includes(domain))
    if (isVideoDomain) {
      return true
    }

    const isVideoExtension = videoExtensions.some((extension) =>
      url?.endsWith(extension),
    )
    if (isVideoExtension) {
      return true
    }

    const isPdf = url?.endsWith('.pdf')
    if (isPdf) {
      return false
    }

    return true
  }

  useEffect(() => {
    const handleResourceDownload = async () => {
      try {
        if (shouldOpenInNewTab(resourceDownloadUrl)) {
          setManualDownloadUrl(resourceDownloadUrl)
        } else if (resourceUrl) {
          const response = await fetch(resourceUrl)
          const blob = await response.blob()
          const blobUrl = window.URL.createObjectURL(blob)
          setManualDownloadUrl(blobUrl)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error downloading document', error)
      }
    }
    handleResourceDownload()

    return () => window.URL.revokeObjectURL(manualDownloadUrl)
  }, [])

  const inputProps = {
    form,
    formContent,
    isLoading,
    resetForm,
    forceSetDebouncedEmail,
  }

  if (isSubmitted) return <SubmittedForm message={formContent.success_message} downloadUrl={manualDownloadUrl} downloadText={downloadText} filename={filename} />

  if (resourceUrl === '/' || typeof resourceUrl === 'undefined') return <DownloadNotFound />

  return (
    <FormWrapper id="formWrapper">
      <form className="downloadForm" id={formId} onSubmit={handleSubmit}>
        <GoogleAnalyticsHiddenFields />
        <input name="website" type="hidden" value={downloadFromURL} />

        <FormState inputProps={inputProps} />

        {didShowGDPRConsent || shouldAskGDPRPermission ? (
          <FormGroup className="gdpr-consent">
            <FormControlLabel
              control={
                <Checkbox
                  id="GDPRConsent"
                  name="GDPRConsent"
                  onChange={form.handleChange}
                  onClick={() => setDidShowGDPRConsent(true)}
                  value={form.values.GDPRConsent}
                />
              }
              label={formContent?.gdpr_consent_language}
            />
            {form.touched.GDPRConsent && Boolean(form.errors.GDPRConsent) ? (
              <span className="errorText">{form.errors.GDPRConsent}</span>
            ) : null}
          </FormGroup>
        ) : null}

        <Grid alignItems="baseline" container justifyContent="space-between">
          <div className="smallText">
            {formContent.privacy_policy_cta?.[0]?.copy
              ? parse(formContent.privacy_policy_cta?.[0]?.copy)
              : null}
          </div>

          <Button
            disabled={isLoading || hasPendingChanges}
            isLoading={form.isSubmitting}
            type="submit"
            variant="secondary"
          >
            {formContent.submit_text}
          </Button>
        </Grid>
      </form>
      <FormDebugger data={{ inputProps }} />
    </FormWrapper>
  )
}

export default DownloadResourceForm
