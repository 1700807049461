import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ClearIcon from '@material-ui/icons/Clear'
import { useStore } from '@nanostores/react'

import { BusinessEmailInput, CompanyNameInput, CountryInput, FirstNameInput, JobTitleInput, LastNameInput } from './fields'
import { cleanString } from '../../utils/helpers'
import { FormLoader } from './styles'
import { $userFormExpanded, storedUser, clearStoredUser } from './store'

export const FormState = ({ inputProps }) => {
  const user = useStore(storedUser)
  const formExpanded = useStore($userFormExpanded)
  const prefilled = Boolean(user.email !== undefined)

  if (prefilled) return <PrefilledBusinessEmailInput inputProps={inputProps} />
  if (formExpanded) return <AllFields inputProps={inputProps} />
  return <EmailOnly inputProps={inputProps} />
}

export const PrefilledBusinessEmailInput = ({ inputProps }) => {
  const user = useStore(storedUser)
  const label = cleanString(inputProps.formContent.business_email_label?.[0]?.copy)

  const reset = () => {
    inputProps.resetForm()
    clearStoredUser()
  }
  React.useEffect(() => {
    inputProps.form.setValues({ businessEmail: user.email })
  }, [])

  return (
    <>
      <Typography className="formSubhead" component="h3" variant="h3">
        Hi, welcome back {user.name}!
      </Typography>
      <Grid container>
        <TextField
          InputProps={{
            endAdornment: (
              <ClearIcon
                className="clearEmailIcon"
                fontSize="medium"
                id="clearEmailIcon"
                onClick={reset}
              />
            ),
            readOnly: true,
          }}
          className={`textField customBackgroundInput`}
          disabled={true}
          id="businessEmail"
          label={label}
          name="businessEmail"
          value={user.email}
          variant="filled"
        />
      </Grid>
    </>
  )
}
const Row = ({ children }: { children: React.ReactNode }) => (
  <Grid alignItems="flex-start" container justifyContent="space-between">
    {children}
  </Grid>
)

export const EmailOnly = ({ inputProps }) => {
  const { form, formContent, isLoading } = inputProps
  const [isEmailFocused, setIsEmailFocused] = React.useState(false)
  const businessEmailAddressRef = React.useRef<HTMLInputElement>(null)

  return (
    <>
      <Typography className="formSubhead" component="h3" variant="h3">
        {inputProps.formContent.headline}
      </Typography>

      <Row>
        <BusinessEmailInput
          form={form}
          formContent={formContent}
          isLoading={Boolean(isLoading && !isEmailFocused)}
          onBlur={(event) => {
            inputProps.forceSetDebouncedEmail(event.target.value)
            setIsEmailFocused(false)
          }}
          onFocus={() => {
            setIsEmailFocused(true)
          }}
          ref={businessEmailAddressRef}
        />
      </Row>
      {isLoading && !isEmailFocused ? <FormLoader /> : null}
    </>
  )
}

export const AllFields = ({ inputProps }) => (
  <>
    <EmailOnly inputProps={inputProps} />
    <Row>
      <FirstNameInput {...inputProps} />
      <LastNameInput {...inputProps} />
    </Row>

    <>
      <Row>
        <CompanyNameInput {...inputProps} />
        <JobTitleInput {...inputProps} />
      </Row>

      <Row>
        <CountryInput {...inputProps} />
      </Row>
    </>
  </>
)
